import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BookingManagerService } from '../booking-manager.service';
import { TooltipService } from '../../../shared/tooltip.service';
import { CustomCalendarHeaderComponent } from '../components/custom-calendar-header/custom-calendar-header.component';
import { stateTooltip } from '../../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
import { DevicehomeService } from '../../device-homepage/devicehome.service';
import { TokenData } from '../../../shared/interfaces';
import { CommonService } from '../../../shared/common.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeviceBooking } from '../interfaces';
import { FeatureTypeEnum } from '../../../shared/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-manager',
  templateUrl: './booking-manager.component.html',
  styleUrls: ['./booking-manager.component.scss']
})
export class BookingManagerComponent implements OnInit, OnDestroy {
  userData: TokenData;
  currentDate = new Date();
  isFiltered = false;
  isFilterOpen = false;
  searchText = '';
  searchDeviceType = '';
  searchLab = '';
  searchLocation = '';
  searchDeviceTypeTemp = '';
  searchLabTemp = '';
  searchLocationTemp = '';
  tooltipComponent = null;
  CustomCalendarHeaderComponent = CustomCalendarHeaderComponent;
  locationListDropdown = [];
  labListDropdown = [];
  deviceList = [];
  deviceListTemp = [];
  isBookingAvailable = false;
  modalRef: NgbModalRef;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'lg',
  };
  bookings: DeviceBooking[] = [];
  bookingSubscription: Subscription;
  selectedDevices = [];
  selectedDevicesSubscription: Subscription;
  selectedDate = new Date();
  selectedDateSubscription: Subscription;

  @ViewChild('bookingModal', { static: false }) bookingModal: TemplateRef<void>;

  constructor(public bookingService: BookingManagerService,
    private tooltipService: TooltipService,
    private deviceHome: DevicehomeService,
    public common: CommonService,
    private modalService: NgbModal
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
    this.bookingSubscription = this.bookingService.bookings$.subscribe((list) => this.bookings = list);
    this.selectedDevicesSubscription = this.bookingService.selectedDevices$.subscribe((devices) => this.selectedDevices = devices);
    this.selectedDateSubscription = this.bookingService.selectedDate$.subscribe((date) => this.selectedDate = date);
  }

  ngOnInit(): void {
    this.userData = this.common.getUser();
    this.isBookingAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.BOOKING]);
    if (this.isBookingAvailable) {
      this.searchDevice();
      this.getDeviceLocations();
      this.bookingService.updateBookingList();
    }
  }

  ngOnDestroy(): void {
    this.bookingSubscription.unsubscribe();
    this.selectedDevicesSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  searchDevice(): void {
    const query = 'showAll=true';
    this.bookingService.startLoading();
    this.deviceHome.availableDevice(query).subscribe((res) => {
      this.bookingService.stopLoading();
      if (res['message'] === 'Success') {
        this.deviceList = res.data.deviceList;
        this.labListDropdown = [];
        this.deviceList.forEach((element, i) => {
          element['showId'] = false;
          if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
            element['deviceStateCode'] = 'TESTING';
          }
          if (element['labName']) {
            this.labListDropdown.push(element['labName']);
          }
          if (element['deviceCommunicationStatus'].toLowerCase() === 'remote-testing') {
            element['deviceStateCode'] = 'TESTING';
          }
          if (element['deviceStateCode'] && element['deviceStateCode'].toLowerCase() === 'removed') {
            element['deviceStateCode'] = 'DISCONNECTED';
          }
          if (!element['deviceStateCode'] || (element['deviceStateCode']
            && !stateTooltip[element['deviceStateCode'].toLowerCase()])) {
            element['deviceStateCode'] = 'UNKNOWN';
          }
          element['isResetting'] = false;
          element['resetCount'] = 0;
          element['isBook'] = false;
          element['searchEndDate'] = '';
          element['minDateCal'] = new Date(Date.now() + (3600 * 1000 * 24));
          element.isDisabled = element.deviceStateCode !== 'ONLINE';
          this.deviceHome.getDeviceDynamicFields(element.serialNumber).subscribe((res) => {
            if (res.message === 'Success') {
              const phone = res.data.properties.find((prop) => prop.name === 'phone');
              element.phoneNumber = phone?.value || '';
            }
          });
        });
        this.deviceListTemp = res.data.deviceList;
        this.labListDropdown = this.labListDropdown.filter(this.onlyUnique);
        if (res.data.deviceList.length === 0) {
          this.deviceList = [];
        }
        if (this.selectedDevices.length > 0) {
          this.bookingService.setSelectedDevices(this.deviceList.filter((device) => this.selectedDevices.some((d) => d._id === device._id)));
        }
      }
    }, err => {
      this.bookingService.stopLoading();
    });

  }

  searchChange() {
    const keys = ['serialNumber', 'modelName', 'osVersion'];
    const values = [];
    if (this.searchText) {
      values.push(this.searchText.toLowerCase());
    }
    const regex = new RegExp(values.join('|'));
    const list = this.isFiltered ? this.deviceList : this.deviceListTemp;
    this.deviceList = list.filter(e => keys.some(k => regex.test(e[k]?.toLowerCase())));
    this.isFiltered = !!(this.searchDeviceType || this.searchLab || this.searchLocation || this.searchText);
  }

  openFilters() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  cancelFilters() {
    this.openFilters();
    this.searchDeviceTypeTemp = this.searchDeviceType;
    this.searchLabTemp = this.searchLab;
    this.searchLocationTemp = this.searchLocation;
  }

  clearFilters() {
    this.searchText = '';
    this.searchDeviceTypeTemp = '';
    this.searchLabTemp = '';
    this.searchLocationTemp = '';
    this.searchDeviceType = '';
    this.searchLab = '';
    this.searchLocation = '';
    this.filterDevices();
    this.openFilters();
  }

  applyFilters() {
    this.isFilterOpen = false;
    this.searchDeviceType = this.searchDeviceTypeTemp;
    this.searchLab = this.searchLabTemp;
    this.searchLocation = this.searchLocationTemp;
    this.filterDevices();
  }

  filterDevices() {
    let list = [...this.deviceListTemp];
    if (this.searchDeviceType) {
      list = list.filter((device) => {
        return this.searchDeviceType === 'apple' ? device.oem === 'Apple' : device.oem !== 'Apple';
      });
    }
    if (this.searchLab) {
      list = list.filter((device) => device.labName === this.searchLab);
    }

    if (this.searchLocation) {
      list = list.filter((device) => device.labLocation === this.searchLocation);
    }
    this.deviceList = list;
    this.isFiltered = !!(this.searchDeviceType || this.searchLab || this.searchLocation || this.searchText);
    if (this.searchText) {
      this.searchChange();
    }
  }

  closeModal() {
    this.modalRef.close();
    this.bookingService.selectedTimeSlot = { hour: -1, device: -1, half: -1, booking: null };
  }

  devicesBooked() {
    this.bookingService.updateBookingList();
    this.modalRef.close();
    this.bookingService.selectedTimeSlot = { hour: -1, device: -1, half: -1, booking: null };
  }

  openBookingModal() {
    this.modalRef = this.modalService.open(this.bookingModal, this.modelOptions);
  }

  onSelectTimeSlot() {
    this.openBookingModal();
  }

  selectDevice($event: MatCheckboxChange, device: any) {
    if ($event.checked) {
      this.bookingService.setSelectedDevices([...this.selectedDevices, device]);
    } else {
      this.bookingService.setSelectedDevices(this.selectedDevices.filter((d) => d.serialNumber !== device.serialNumber));
    }
    this.bookingService.updateBookingList();
  }

  isDeviceSelected(device): boolean {
    return this.selectedDevices.some(d => d._id === device._id);
  }

  onDateSelection($event: Date) {
    this.bookingService.setSelectedDate($event);
    this.bookingService.updateBookingList();
  }

  getDeviceLocations() {
    this.bookingService.startLoading();
    this.deviceHome.getLocationAPI('query').subscribe((res) => {
      this.bookingService.stopLoading();
      if (res['message'] === 'Success') {
        this.locationListDropdown = res.data.locationList;
      }
    }, () => this.bookingService.stopLoading());
  }
}
