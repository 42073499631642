<div class="modal-header">
    <h5 class="modal-title">{{isEdit() ? 'Update ' : ''}}Booking</h5>
    <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"
        class="close_modal-window">
        <path fill="#fff"
            d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
    </svg>
</div>
<div class="modal-body">
    <div class="table-wrapper">
        <table class="devices-table">
            <thead>
                <tr>
                    <th>Device</th>
                    <th>OEM</th>
                    <th>OS</th>
                    <th>Location</th>
                    <th>Phone Number</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let device of selectedDevices">
                    <td>
                        <div class="checkbox_wrapper">
                            <mat-checkbox (change)="selectForBooking($event, device)"
                                [checked]="isDeviceSelected(device)"></mat-checkbox>
                            {{device.modelName}}
                        </div>
                    </td>
                    <td>{{device.oem}}</td>
                    <td>{{device.osVersion}}</td>
                    <td>{{device.labLocation}}</td>
                    <td>{{device.phoneNumber}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="error_list" *ngIf="errors.length > 0">
        <span>{{errorMessage}}</span>
        <span *ngFor="let error of errors">
            {{error.deviceName}} - from {{error.bookedFrom | date: "MM/dd/yyyy hh:mm a"}} to {{error.bookedUntil | date:
            "MM/dd/yyyy hh:mm a"}}
        </span>
    </div>
    <label for="fromDate">From:</label>
    <div class="datetime_input_wrapper">
        <div class="date_input_wrapper">
            <input id="fromDate" placeholder="mm/dd/yyyy" matInput [matDatepicker]="fromPicker" readonly
            [min]="startDate" [max]="maxDate" (click)="fromPicker.open()"
            (dateChange)="onDateChange($event, 'bookingFrom')" [value]="bookingFrom" style="width: 100% !important;">
            <mat-datepicker-toggle class="picker_icon" matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </div>
        <div class="time_input_wrapper">
            <input placeholder="12:00 AM" (change)="onTimeChange($event, 'bookingTimeFrom')" [value]="bookingTimeFrom">
            <mat-icon class="time_icon" (click)="timeFromSelect.open()">history</mat-icon>
            <mat-select class="time_select" (selectionChange)="onTimeSelect($event, 'bookingTimeFrom')" #timeFromSelect>
                <mat-option *ngFor="let time of times" [value]="time">
                    {{time.hour === '00' ? '12': time.hour > 12 ? (time.hour - 12).toString().padStart(2, '0') :
                    time.hour}}:{{time.minute}} {{time.hour > 11 ? 'PM' : 'AM'}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <label for="toDate">To:</label>
    <div class="datetime_input_wrapper">
        <div class="date_input_wrapper">
            <input id="toDate" placeholder="mm/dd/yyyy" matInput [matDatepicker]="toPicker" readonly [min]="minDate"
                [max]="maxDate" (click)="toPicker.open()" (dateChange)="onDateChange($event, 'bookingTo')"
                [value]="bookingTo" style="width: 100% !important;">
            <mat-datepicker-toggle class="picker_icon" matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </div>
        <div class="time_input_wrapper">
            <input placeholder="12:00 AM" (change)="onTimeChange($event, 'bookingTimeTo')" [value]="bookingTimeTo">
            <mat-icon class="time_icon" (click)="timeToSelect.open()">history</mat-icon>
            <mat-select class="time_select" (selectionChange)="onTimeSelect($event, 'bookingTimeTo')" #timeToSelect>
                <mat-option *ngFor="let time of timesTo" [value]="time">
                    {{time.hour === '00' ? '12': time.hour > 12 ? (time.hour - 12).toString().padStart(2, '0') :
                    time.hour}}:{{time.minute}} {{time.hour > 11 ? 'PM' : 'AM'}}
                </mat-option>
            </mat-select>
        </div>
        <!-- TODO edit recurrent -->
        <div *ngIf="!isEdit()">
            <mat-select class="recurrence_select" [(value)]="type" (selectionChange)="onTypeChange($event)">
                <mat-option [value]="'SINGLE'">Does Not Repeat</mat-option>
                <mat-option [value]="'DAILY'">Daily</mat-option>
                <mat-option [value]="'WEEKLY'">Weekly</mat-option>
            </mat-select>
        </div>
    </div>
    <label for="endDate" *ngIf="type !== 'SINGLE'">Add End Date</label>
    <div class="datetime_input_wrapper" *ngIf="type !== 'SINGLE'">
        <div class="date_input_wrapper">
            <input id="endDate" placeholder="mm/dd/yyyy" matInput [matDatepicker]="endPicker" readonly [min]="minDate"
            [max]="maxEndDate" (click)="endPicker.open()" (dateChange)="onDateChange($event, 'endDate')"
                [value]="endDate">
            <mat-datepicker-toggle class="picker_icon" matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </div>
    </div>
    <label for="duration">Duration</label>
    <div class="datetime_input_wrapper">
        <input id="duration" placeholder="0 days 0 hours 0 minutes" [value]="duration"
            (change)="onDurationChange($event)">
        <span [tp]="tooltipComponent" [tpData]="(!duration ? 'Select starting time and duration' : '') | tooltipData">
            <button class="new-button new-secondary" [disabled]="!duration || type !== 'SINGLE'"
                (click)="findBestSlot()">
                Find best slot
            </button>
        </span>
    </div>
    <label for="description">Description</label>
    <textarea placeholder="Add description" class="description_textbox" cols="5" [value]="description"
        (change)="onDescriptionChange($event)"></textarea>
    <div class="modal-footer">
        <button class="new-button new-secondary" (click)="closeModal()">Cancel</button>
        <button class="new-button new-primary" [disabled]="checkCreateReady()" (click)="submitForm()">{{isEdit() ?
            'Update ' : 'Create'}}</button>
    </div>
</div>

<ng-template #bestSlotModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Best slot</h5>
        <svg (click)="closeSlotModal()" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"
            class="close_modal-window">
            <path fill="#fff"
                d="M256 863.652 192.348 800l224-224-224-224L256 288.348l224 224 224-224L767.652 352l-224 224 224 224L704 863.652l-224-224-224 224Z" />
        </svg>
    </div>
    <div class="modal-body">
        <span class="best_slot_info" *ngIf="!bestSlot">Could not suitable time slot</span>
        <div class="best_slot_wrapper best_slot_info" *ngIf="bestSlot">
            <span>Suggested time slot</span>
            <span>From: {{bestSlot.startDateTime * 1000 | date: "MM/dd/yyyy hh:mm a"}}</span>
            <span>To: {{(bestSlot.endDateTime + 1) * 1000 | date: "MM/dd/yyyy hh:mm a"}}</span>
        </div>
        <div class="modal-footer">
            <button class="new-button new-secondary" (click)="closeSlotModal()">
                {{bestSlot ? 'Cancel' : 'Close'}}
            </button>
            <button *ngIf="bestSlot" class="new-button new-primary" (click)="applyBestSlot()">Apply</button>
        </div>
    </div>
</ng-template>
