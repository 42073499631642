import { Component, ElementRef, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../../shared/common.service';
import { EventEmitter } from '@angular/core';
import { TooltipService } from '../../../shared/tooltip.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Booking, DeviceBooking } from '../interfaces';
import { BookingManagerService } from '../booking-manager.service';
import { Subscription } from 'rxjs';
import { DialogModalService } from '../../../shared/dialog-modal.service';
import { stateTooltip } from '../../device-homepage/device-homepage-management-new/device-homepage-management.component-new';
import { TokenData } from '../../../shared/interfaces';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-booking-scheduler',
    templateUrl: './booking-scheduler.component.html',
    styleUrls: ['./booking-scheduler.component.scss']
})
export class BookingSchedulerComponent implements OnInit, OnDestroy {
    tooltipComponent = null;
    bookings: DeviceBooking[] = [];
    bookingSubscription: Subscription;
    selectedDevices = [];
    selectedDevicesSubscription: Subscription;
    selectedDate = new Date();
    selectedDateSubscription: Subscription;
    userData: TokenData;
    slots = [];

    constructor(
        private commonService: CommonService,
        private tooltipService: TooltipService,
        public bookingService: BookingManagerService,
        private dialogModalService: DialogModalService,
        private sanitizer: DomSanitizer
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
        this.bookingSubscription = this.bookingService.bookings$.subscribe((list) => {
            this.bookings = list;
            setTimeout(() => {
                this.placeBookingsOnTheGrid();
            }, 800);
        });
        this.selectedDevicesSubscription = this.bookingService.selectedDevices$.subscribe((devices) => {
            this.selectedDevices = devices;
            this.scrollToClosest();
            setTimeout(() => {
                this.placeBookingsOnTheGrid();
            }, 800);
        });
        this.selectedDateSubscription = this.bookingService.selectedDate$.subscribe((date) => {
            this.slots = [];
            this.selectedDate = date;
            this.scrollToClosest();
            setTimeout(() => {
                this.placeBookingsOnTheGrid();
            }, 800);
        });

    }

    @Output() selectSlot = new EventEmitter();
    @ViewChild('scheduler') scheduler: ElementRef<HTMLElement>;
    @ViewChild('drawer') drawer: MatDrawer;

    isDeviceInfoShown = [];
    hours = Array.from(Array(24)).map((_x, i) => i);

    ngOnInit(): void {
        this.isDeviceInfoShown = this.selectedDevices.map((_d) => false);
        this.userData = this.commonService.getUser();
    }

    ngOnDestroy(): void {
        this.bookingSubscription.unsubscribe();
        this.selectedDevicesSubscription.unsubscribe();
        this.selectedDateSubscription.unsubscribe();
    }

    scrollToClosest() {
        if (this.checkToday()) {
            const hour = this.bookingService.currentDate.getHours();
            setTimeout(() => {
                const closestSlot = document.getElementById(`${hour - 1}-${this.selectedDevices[0]?._id}`);
                closestSlot?.scrollIntoView();
            }, 100);
        } else {
            setTimeout(() => {
                const bookedSlots = document.getElementsByClassName('self_booked');
                this.scheduler.nativeElement.scrollTo(0, bookedSlots.length > 0 ? bookedSlots.item(0)['offsetTop'] - 170 : 0);
            }, 100);
        }
    }

    checkToday(): boolean {
        return this.selectedDate.getDate() === this.bookingService.currentDate.getDate()
            && this.selectedDate.getMonth() === this.bookingService.currentDate.getMonth()
            && this.selectedDate.getFullYear() === this.bookingService.currentDate.getFullYear();
    }

    checkMaxDate(): boolean {
        return this.selectedDate.getDate() === this.bookingService.maxDate.getDate()
            && this.selectedDate.getMonth() === this.bookingService.maxDate.getMonth()
            && this.selectedDate.getFullYear() === this.bookingService.maxDate.getFullYear();
    }

    changeDay(direction: number) {
        this.bookingService.setSelectedDate(new Date(this.selectedDate.getTime() + 86400000 * direction));
        this.bookingService.updateBookingList();
    }

    displayDay(): string {
        return this.selectedDate.toLocaleString('en-us', { month: 'long', day: 'numeric', year: 'numeric' }).toUpperCase();
    }

    removeDevice(index: number) {
        this.selectedDevices.splice(index, 1);
        this.isDeviceInfoShown = this.selectedDevices.map((_d) => false);
    }

    showDeviceInfo(index: number) {
        this.isDeviceInfoShown[index] = !this.isDeviceInfoShown[index];
    }

    checkPartialHour(hour: number, device: any): boolean {
        const firstBooking = this.findBooking(hour, device, 1);
        const secondBooking = this.findBooking(hour, device, 2);
        if (firstBooking && firstBooking?.id === secondBooking?.id) return false;
        return true;
    }

    selectTimeSlot(hour: number, deviceIndex: number, half: number) {
        const booking = this.findBooking(hour, this.selectedDevices[deviceIndex], half);
        this.bookingService.selectedTimeSlot = { hour, device: deviceIndex, half, booking };
        if (!booking) {
            this.selectSlot.emit();
        }
    }

    findBooking(hour: number, device: any, half: number): Booking {
        const date = new Date(this.selectedDate.getTime());
        date.setHours(hour);
        date.setMinutes(half > 1 ? 30 : 0);
        date.setSeconds(0);
        const time = Math.round(date.getTime() / 1000);
        const deviceBooking = this.bookings.find((d) => d.deviceId === device._id);
        return deviceBooking?.bookings.find((booking) => {
            return booking.bookedFrom <= time && booking.bookedUntil > time + 59;
        });
    }

    findBookingEntry(deviceId: string, bookingId: string) {
        const deviceBooking = this.bookings.find((d) => d.deviceId === deviceId);
        return deviceBooking?.bookings.find((booking) => {
            return bookingId === booking.id;
        });
    }

    checkSelfBooked(hour: number, device: any, half: number): boolean {
        const booking = this.findBooking(hour, device, half);
        return booking?.userId === this.userData.userId;
    }

    checkBooking(slot: any) {
        return slot.userId === this.userData.userId;
    }

    checkOtherBooked(hour: number, device: any, half: number): boolean {
        const booking = this.findBooking(hour, device, half);
        return booking && booking.userId !== this.userData.userId;
    }

    displayBookingDuration(booking: Booking): string {
        const duration = booking.bookedUntil - booking.bookedFrom;
        const hours = Math.floor(duration / 3600);
        const mins = Math.round((duration - (hours * 3600)) / 60);
        return `${hours}h ${mins}m`;
    }

    toggleSidepanelAction(): void {
        this.drawer.toggle();
    }

    toggleOwnBooking($event) {
        this.bookingService.toggleOwnBooking($event.target.checked);
    }

    editBooking($event, hour: number, deviceIndex: number, half: number) {
        $event.stopPropagation();
        const booking = this.findBooking(hour, this.selectedDevices[deviceIndex], half);
        this.bookingService.selectedTimeSlot = { hour, device: deviceIndex, half, booking };
        if (booking) {
            this.selectSlot.emit();
        }
    }

    editBookingEntry($event, deviceId: string, bookingId: string) {
        $event.stopPropagation();
        const booking = this.findBookingEntry(deviceId, bookingId);
        this.bookingService.selectedTimeSlot = { hour: -1,
            device: this.selectedDevices.indexOf(this.selectedDevices.find((d) => deviceId === d._id)),
            half: -1, booking };
        if (booking) {
            this.selectSlot.emit();
        }
    }

    openDeleteModal($event, hour: number, device: any, half: number) {
        $event.stopPropagation();
        const booking = this.findBooking(hour, device, half);
        if (booking) {
            this.dialogModalService.openConfirmationDialog('deleteBooking', () => this.deleteBooking(booking));
        }
    }

    openDelete($event, deviceId: string, bookingId: string) {
        $event.stopPropagation();
        const booking = this.findBookingEntry(deviceId, bookingId);
        if (booking) {
            this.dialogModalService.openConfirmationDialog('deleteBooking', () => this.deleteBooking(booking));
        }
    }

    deleteBooking(booking: Booking) {
        this.bookingService.startLoading();
        this.bookingService.deleteBookings([booking.id]).subscribe((res) => {
            this.bookingService.stopLoading();
            if (res.statusCode === 200) {
                this.bookingService.updateBookingList();
            }
        }, () => this.bookingService.stopLoading());
    }

    getDeviceStatus(data: any): string {
        const state = data.deviceState;
        const stateCode = data.deviceStateCode;
        const commStatus = data.deviceCommunicationStatus;
        let displayed = 'unknown';
        if (state) {
            if (state.toLowerCase() === 'online') {
                if (stateCode.toLowerCase() === 'online') {
                    if (commStatus.toLowerCase() === 'remote-testing' || data.testingType) {
                        displayed = 'testing';
                    } else {
                        displayed = 'online';
                    }
                } else {
                    if (stateTooltip[stateCode.toLowerCase()]) {
                        displayed = stateCode.toLowerCase();
                    }
                }
            } else if (state.toLowerCase() === 'offline') {
                if (stateCode && stateCode !== '') {
                    if (stateCode.toLowerCase() === 'removed') {
                        displayed = 'disconnected';
                    } else {
                        if (stateTooltip[stateCode.toLowerCase()]) {
                            displayed = stateCode.toLowerCase();
                        }
                    }
                }
            } else if (state.toLowerCase() === 'blocked') {
                displayed = state.toLowerCase();
            }
        }
        return displayed;
    }

    getDisplayedStatus(status: string): string {
        let res = status;
        switch (status) {
            case 'testing':
                res = 'under test';
                break;
            case 'online':
                res = 'available';
                break;
        }
        return res;
    }

    private placeBookingsOnTheGrid() {
        const startOfDay = this.selectedDate;
        startOfDay.setHours(0, 0, 0, 0);
        if (this.bookings.length && this.selectedDevices.length) {
            this.slots = [];
            this.bookings.forEach((device) => {
                const deviceIndex = this.selectedDevices.indexOf(this.selectedDevices.find((d) => device.deviceId === d._id));
               if (device.bookings.length > 0) {
                   device.bookings.forEach((booking) => {
                       if ( startOfDay.getDate() - new Date(booking.bookedFrom * 1000).getDate() >= 0
                       && startOfDay.getDate() - new Date(booking.bookedUntil * 1000).getDate() <= 0) {
                           const ratio = deviceIndex > 0 ? deviceIndex / this.selectedDevices.length : 0;
                           const left = `calc(100px + ${ratio} * 100% - ${ratio} * 107px)`;
                           const top = new Date(booking.bookedFrom * 1000).getDate() === startOfDay.getDate() ? 60 + (booking.bookedFrom / 60 - startOfDay.getTime() / 60000) : 60;
                           let height = '0px';
                           // 1500px - magic number - fixed height of the container for bookings
                           if (new Date(booking.bookedFrom * 1000).getDate() === startOfDay.getDate()) {
                               if (new Date(booking.bookedUntil * 1000).getDate() === startOfDay.getDate()) {
                                   height = `${(booking.bookedUntil - booking.bookedFrom) / 60}px`;
                               } else {
                                   height = `${1500 - top}px`;
                               }
                           } else {
                               if (new Date(booking.bookedUntil * 1000).getDate() === startOfDay.getDate()) {
                                   height = `${(booking.bookedUntil - startOfDay.getTime() / 1000) / 60}px`;
                               } else {
                                   height = '1440px';
                               }
                           }
                           this.slots.push(
                               {
                                   userId: booking.userId,
                                   deviceId: device.deviceId,
                                   bookingId: booking.id,
                                   top: 60 + (booking.bookedFrom / 60 - startOfDay.getTime() / 60000),
                                   size: this.sanitizer.bypassSecurityTrustStyle(`height:${height};left:${left};top:${top}px;width:${'calc(100% / ' + this.selectedDevices.length + ' - 110px / ' + this.selectedDevices.length + ')'};`)
                               }
                           );
                       }
                   });
               }
            });
        }
    }
}
