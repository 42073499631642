import {CustomTestService} from '../custom-test.service';
import {
    CustomDeviceInfo,
    CustomTestExecution,
    CustomTestExecutionStatusEnum,
    CustomTestFormStep,
    CustomTestReplay,
    CustomTestTemplate,
    RunTestExecutionForm
} from '../interfaces';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '../../../shared/common.service';
import {TooltipService} from '../../../shared/tooltip.service';
import {DialogModalService} from '../../../shared/dialog-modal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ComponentCanDeactivate} from '../../../guards/pending-changes-guard';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import {interval, Subject, Subscription} from 'rxjs';
import {FeatureTypeEnum} from '../../../shared/enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

interface DeviceSelectionCommand {
    shouldDiscard?: boolean;
    shouldBook?: boolean;
    devices?: CustomDeviceInfo[];
    subject?: Subject<boolean>;
}

@Component({
    selector: 'custom-new-test',
    templateUrl: './custom-new-test.component.html',
    styleUrls: ['./custom-new-test.component.scss']
})

export class CustomNewTestComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    testRepeatCount = '1';
    iterationDelay = '1';
    testName = '';
    artifactSelection = {};
    tests = [];
    isLoading = 0;

    selectedDevices: Record<string, any> = {};

    isTestRunning = false;
    testInfo: CustomTestExecution;
    iterations: CustomTestReplay[] = [];
    pageLimit = 10;
    currentPage = 0;
    totalPage = 0;

    pollingInterval: Subscription;
    tooltipComponent = null;

    isTelephonyAvailable = false;
    isCloudAvailable = false;
    selectedTestCase: CustomTestTemplate;
    ignoreTyping = false;
    paginationText = '0-0 of 0';
    totalIterations = 0;
    setDevices: Subject<DeviceSelectionCommand> = new Subject();
    isCustomizeMode = false;
    paramsForm: FormGroup;
    currentPlayStart = 0;
    completedIterations = 0;
    testCaseId = '';
    testExecutionId = '';
    templateParameters = [];
    isExpandedIterations = false;
    progressBarValue = 0;

    constructor(public common: CommonService,
        private customTestService: CustomTestService,
        private tooltipService: TooltipService,
        private dialogModalService: DialogModalService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private fb: FormBuilder,
    ) {
        this.tooltipComponent = this.tooltipService.getTooltipComponent();
    }

    ngOnInit() {
        this.isTelephonyAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.TELEPHONY_TEST]);
        this.isCloudAvailable = this.common.checkFeatureAccess([FeatureTypeEnum.CLOUD]);
        this.testCaseId = this.route.snapshot.queryParamMap.get('id');
        this.testExecutionId = this.route.snapshot.queryParamMap.get('executionId');

        if (this.isTelephonyAvailable) {
            this.getTestTemplate();
        }
    }

    ngOnDestroy(): void {
        if (this.pollingInterval) {
            this.pollingInterval.unsubscribe();
        }
    }

    canDeactivate() {
        return this.isTestRunning || !this.isAnyDeviceSelected();
    }

    getTestTemplate() {
        this.isLoading++;
        this.customTestService.getTestTemplate(this.testCaseId).subscribe((res) => {
            this.isLoading--;
            this.selectedTestCase = res;
            res.devices.forEach((device) => this.artifactSelection[device.index] = {});

            if (this.testExecutionId) {
                this.getInitialTestInfo();
            } else {
                this.createParameters(res.steps)
            }
        }, () => this.isLoading--);
    }

    getInitialTestInfo() {
        this.isLoading++;
        this.customTestService.getTestExecution(this.testExecutionId).subscribe((res) => {
            this.isLoading--;
            this.testInfo = res;
            this.currentPlayStart = res.activeReplay?.startedAt;
            this.completedIterations = res.analytics?.completedReplayCount;
            this.totalIterations = res.replayCount;

            if (res.status === CustomTestExecutionStatusEnum.EXECUTING || res.status === CustomTestExecutionStatusEnum.RUNNING) {
                this.isTestRunning = true;
                this.getIterations();
                this.startPolling();
            } else {
                this.iterationDelay = `${res.replayDelay / 1000}`;
                this.testRepeatCount = `${res.replayCount}`;

                res.logs.forEach((log) => {
                    log.artifacts?.forEach((artifact) => {
                        this.artifactSelection[log.deviceIndex] = { [artifact.type]: true };
                    });
                });

                this.createParameters(res.steps);
                this.router.navigate([], { queryParams: { 'id': null, 'executionId': null }, queryParamsHandling: 'merge' });
            }

            if (res.devices?.some((device) => device.serialNumber)) {
                const command = {
                    shouldBook: res.status !== CustomTestExecutionStatusEnum.EXECUTING && res.status !== CustomTestExecutionStatusEnum.RUNNING,
                    devices: res.devices.map((device) => ({
                        sn: device.serialNumber,
                        os: device.osName,
                        name: device.modelName,
                        index: device.index,
                        noVerifications: device.noVerifications
                    }))
                };
                this.setDevices.next(command);
            }
        }, () => this.isLoading--);
    }

    createParameters(steps: CustomTestFormStep[]) {
        const formObject = {};
        steps.forEach((step, stepIndex) => {
            step.action.data.payload.forEach((payload) => {
                const parameter = payload.parameters.find((param) => param.initPlace === 'RUN_TEST' && param.key !== 'serialNumber');
                if (parameter) {
                    const validators = [Validators.required];
                    const constraints = {
                        required: {
                            message: parameter.name + 'is required'
                        }
                    };
                    parameter.constraints.forEach((constraint) => {
                        if (constraint.name.toLocaleLowerCase() === 'max') {
                            validators.push(
                                parameter.type === 'text'
                                    ? Validators.maxLength(constraint.value)
                                    : Validators.max(constraint.value)
                            );
                        }
                        if (constraint.name.toLocaleLowerCase() === 'min') {
                            validators.push(
                                parameter.type === 'text'
                                    ? Validators.minLength(constraint.value)
                                    : Validators.min(constraint.value)
                            );
                        }
                        constraints[constraint.name.toLocaleLowerCase()] = {
                            message: constraint.description,
                            value: constraint.value
                        }
                    });

                    formObject[stepIndex + payload.key + parameter.key] = [parameter.value || '', validators];

                    this.templateParameters.push({
                        type: parameter.type,
                        name: stepIndex + payload.key + parameter.key,
                        label: `Step ${stepIndex + 1} ${parameter.name}`,
                        placeholder: parameter.description,
                        constraints
                    });
                }
            });
        });

        this.paramsForm = this.fb.group(formObject);
    }

    showWarningModal() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                header: 'Save or discard custom test execution',
                content: 'In case test is discarded, record will be deleted permanently with all associated artifacts. This operation can\'t be canceled',
                accept: 'Save',
                decline: 'Discard',
                cancel: 'Cancel',
            },
        });
        const subject = new Subject<boolean>();

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'accept') {
                this.isLoading++;

                const newTestForm = this.prepareNewTestDto();
                if (this.testExecutionId) {
                    newTestForm.id = this.testExecutionId;
                    this.customTestService.updateTestExecution(newTestForm, true).subscribe((res) => {
                        this.isLoading--;
                        this.releaseDevices();
                    }, () => this.isLoading--);
                } else {
                    this.customTestService.runTestExecution(newTestForm, true).subscribe((res) => {
                        this.isLoading--;
                        this.releaseDevices();
                    }, () => this.isLoading--);
                }
            } else if (result === 'decline') {
                if (!this.isAnyDeviceSelected()) {
                    subject.next(true);
                    subject.complete();
                }
                this.releaseDevices(subject);
            }
        });
        return subject.asObservable();
    }

    releaseDevices(subject?: Subject<boolean>) {
        const command = { shouldDiscard: true, subject };
        this.setDevices.next(command);
    }

    prepareNewTestDto(): RunTestExecutionForm {
        const repeat = parseInt(this.testRepeatCount);
        const delay = parseInt(this.iterationDelay);

        const devices = Object.values(this.selectedDevices).map((device) => {
            const templateDevice = this.selectedTestCase.devices.find((d) => d.index === device.index);
            return {
                index: device.index,
                serialNumber: device.serialNumber,
                modelName: device.modelName,
                osName: device.os,
                osVersion: device.osVersion,
                noVerifications: templateDevice.noVerifications
            };
        });

        const steps = this.selectedTestCase.steps.map((step, stepIndex) => {
            const payload = step.action.data.payload.map((payload) => {
                const parameters = payload.parameters.map((param) => {
                    if (param.initPlace === 'RUN_TEST') {
                        if (param.key === 'serialNumber') {
                            const createParam = payload.parameters.find((p) => p.initPlace === 'CREATE_TEST');
                            const deviceIndex = createParam?.value;
                            const device = devices.find((device) => device.index === deviceIndex);
                            return { ...param, value: device.serialNumber };
                        }
                        return { ...param, value: this.paramsForm.value[stepIndex + payload.key + param.key] };
                    }
                    return param;
                });
                return { ...payload, parameters };
            });
            return {
                ...step,
                action: {
                    ...step.action,
                    data: { ...step.action.data, payload }
                }
            }
        });

        const logs = Object.keys(this.artifactSelection).map((deviceIndex) => {
            const artifacts = Object.keys(this.artifactSelection[deviceIndex]).filter((artifact) => {
                return this.artifactSelection[deviceIndex][artifact];
            }).map((artifact) => ({ type: artifact }));
            return {
                deviceIndex: parseInt(deviceIndex),
                artifacts
            }
        });

        return {
            testId: this.selectedTestCase.id,
            name: this.testName || this.selectedTestCase.name,
            description: this.selectedTestCase.description,
            replayCount: isNaN(repeat) ? 1 : repeat,
            replayDelay: isNaN(delay) ? 0 : delay * 1000,
            devices,
            steps,
            logs,
        };
    }

    startTest(): void {
        this.isLoading++;
        const newTestForm = this.prepareNewTestDto();

        this.customTestService.runTestExecution(newTestForm).subscribe((res) => {
            this.testInfo = res;
            this.totalIterations = res.replayCount;
            setTimeout(() => {
                this.isLoading--;
                this.isTestRunning = true;
                this.testExecutionId = res.id;
                this.getIterations();
                this.startPolling();
            }, 1000);
        }, () => this.isLoading--);
    }

    getTestInfo() {
        if (this.testInfo.status === CustomTestExecutionStatusEnum.EXECUTING || this.testInfo.status === CustomTestExecutionStatusEnum.RUNNING) {
            this.customTestService.getTestExecution(this.testInfo.id).subscribe((res) => {
                this.testInfo = res;
                this.currentPlayStart = res.activeReplay?.startedAt;
                this.completedIterations = res.analytics?.completedReplayCount;
                this.totalIterations = res.replayCount;
            });
        } else {
            this.pollingInterval?.unsubscribe();
        }
    }

    navigate(path: string, id?: string) {
        if (id) {
            this.router.navigate(['/snap/custom-test-report'], { queryParams: { id } });
        } else {
            this.router.navigate([path]);
        }
    }

    cancelTestCreation() {
        this.releaseDevices();
        this.navigate('/snap/custom-tests');
        this.selectedDevices = {};
    }

    handleArtifactSelection(device: number, type: string, checked: boolean) {
        if (checked) {
            this.artifactSelection[device][type] = checked;
        } else {
            delete this.artifactSelection[device][type];
        }
    }

    openConfirmCancel() {
        this.dialogModalService.openConfirmationDialog('discardCustom', () => this.cancelTestCreation());
    }

    cancelTest() {
        this.isLoading++;
        this.customTestService.stopTestExecution(this.testInfo.id).subscribe((res) => {
            this.isLoading--;
            this.releaseDevices();
            this.navigate('/snap/custom-test-report', this.testInfo.id);
        }, () => this.isLoading--);
    }

    changePageLimit() {
        this.currentPage = 0;
        this.getIterations();
    }

    changePage(direction: number) {
        this.currentPage += direction;
        this.getIterations();
    }

    getIterations() {
        if (this.testInfo.status === CustomTestExecutionStatusEnum.EXECUTING || this.testInfo.status === CustomTestExecutionStatusEnum.RUNNING) {
            this.isLoading++;
            this.customTestService.getTestReplays(this.testExecutionId, this.currentPage, this.pageLimit).subscribe((res) => {
                this.isLoading--;
                if(res.content) {
                    this.iterations = res.content.map((iteration) => {
                        const steps = iteration.steps.map((step) => {
                            let error = '';
                            if (iteration.result?.errors) {
                                const e = iteration.result?.errors.find((error) => error.message.includes(`[Step ${step.action.order}]`));
                                if (e) error =  e.message.replace(`[Replay ${iteration.index}] [Step ${step.action.order}]`, '');
                            }
                            const status = error ? 'FAILED' : step.endedAt ? 'COMPLETED' : step.startedAt ? 'EXECUTING' : 'IDLE';
                            const validators = step.validators?.map((validator) => {
                                let status = 'IDLE';
                                if(error) {
                                    const deviceParam = validator.target.parameters.find((param) => param.key === 'index');
                                    const device = this.testInfo.devices.find((device) => device.index === deviceParam?.value);
                                    if (device && error.includes(device.serialNumber)) status = 'FAILED';
                                } else if (step.endedAt) {
                                    status = 'COMPLETED';
                                }
                                return { ...validator, status };
                            });
                            return {...step, status, error, validators };
                        })
                        return { ...iteration, steps };
                    });
                }
                this.totalPage = res.totalPages;

                this.setPage();
            }, () => this.isLoading--);
        }
    }

    updateIgnoreTyping(v: boolean): void {
        this.ignoreTyping = v;
    }

    setPage() {
        const total = this.totalIterations;
        const max = (this.currentPage + 1) * this.pageLimit;
        this.paginationText = `${(this.currentPage * this.pageLimit) + 1}-${total < max ? total : max} of ${total}`;
    }

    updateSelectedDevices(devices: any) {
        this.selectedDevices = devices;
    }

    switchCustomizeMode(mode: boolean) {
        this.isCustomizeMode = mode;
    }

    isAnyDeviceSelected() {
        return JSON.stringify(this.selectedDevices) !== JSON.stringify({});
    }

    checkTestReady() {
        return this.selectedTestCase?.devices.every((device) => this.selectedDevices[device.index])
            && (!this.paramsForm?.invalid);
    }

    getEstimatedTime(): string {
        if (this.iterations) {
            const remainingIterations = this.totalIterations - this.completedIterations;
            if (!remainingIterations) { return '00:00:00'; }
            const remainingTime = remainingIterations * (this.selectedTestCase.executionTime + this.testInfo.replayDelay) - this.testInfo.replayDelay;
            const currentTime = remainingTime - (new Date().getTime() - this.testInfo.startedAt);

            const totalRemaining = currentTime < 0
                ? Math.abs(Math.round(currentTime / 1000))
                : Math.round(currentTime / 1000);

            const totalTime = Math.round((this.totalIterations * (this.selectedTestCase.executionTime + this.testInfo.replayDelay)) / 1000);

            if (currentTime <= 0 && remainingIterations === 1) {
                this.progressBarValue = 100;
            } else {
                this.progressBarValue = 100 - (totalRemaining / totalTime) * 100;
            }

            const hours = Math.floor(totalRemaining / 3600);
            const mins = Math.floor((totalRemaining - (hours * 3600)) / 60);
            const seconds = Math.round(totalRemaining % 60);

            return `${currentTime < 0 && remainingIterations === 1 ? '-' : ''}${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
    }

    saveTest(): void {
        this.isLoading++;
        const newTestForm = this.prepareNewTestDto();
        if (this.testExecutionId) {
            newTestForm.id = this.testExecutionId;
            this.customTestService.updateTestExecution(newTestForm, true).subscribe((res) => {
                this.isLoading--;
                this.releaseDevices();
                this.navigate('/snap/custom-tests');
            }, () => this.isLoading--);
        } else {
            this.customTestService.runTestExecution(newTestForm, true).subscribe((res) => {
                this.isLoading--;
                this.releaseDevices();
                this.navigate('/snap/custom-tests');
            }, () => this.isLoading--);
        }
        this.selectedDevices = {};
    }

    startPolling() {
        this.pollingInterval = interval(5000).subscribe(() => {
            if (this.testInfo.status !== CustomTestExecutionStatusEnum.EXECUTING && this.testInfo.status !== CustomTestExecutionStatusEnum.RUNNING) {
                this.pollingInterval.unsubscribe();
                this.isTestRunning = false;
                this.releaseDevices();
                this.navigate('/snap/custom-test-report', this.testExecutionId);
                this.selectedDevices = {};
            } else {
                this.getTestInfo();
                this.getIterations();
            }
        });
    }

    expandIterations() {
        this.isExpandedIterations = !this.isExpandedIterations;
    }
}
