<app-spinner *ngIf="isLoading"></app-spinner>
<div class="page-title" *ngIf="isTelephonyAvailable">
    <h1>Test Builder</h1>
    <div class="filters" *ngIf="activeTab === TypeEnum.CASE && (totalTestCases !== 0 || checkIfFiltered(1))">
        <!--FILTERS FOR TESTS-->
        <input [(ngModel)]="searchValue" type="text" maxlength="100" (input)="searchTests(1)"
            [ngModelOptions]="{standalone: true}" class="form-control search-field" placeholder="Search" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: checkIfFiltered(1) }" (click)="openFilters()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div *ngIf="totalTestCases !== 0" class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <ul class="tab-container">
                    <li (click)="setDateFilter(FilterType.TODAY)"
                        [ngClass]="{'active': dateFilter === FilterType.TODAY }">
                        Today
                    </li>
                    <li (click)="setDateFilter(FilterType.LAST_WEEK)"
                        [ngClass]="{'active': dateFilter === FilterType.LAST_WEEK }">
                        Last Week
                    </li>
                    <li (click)="setDateFilter(FilterType.CUSTOM)"
                        [ngClass]="{'active': dateFilter === FilterType.CUSTOM }">
                        <span>
                            Custom
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14.3" viewBox="0 0 17.039 14.308"
                                fill="currentColor">
                                <g id="Group_1142" data-name="Group 1142" transform="translate(-7697.717 -1509.119)">
                                    <path id="Path_6" data-name="Path 6"
                                        d="M7709.212,1511.652a.452.452,0,0,0,.139.021h.015a.458.458,0,0,0,.14-.021.472.472,0,0,0,.333-.452v-1.608a.472.472,0,0,0-.333-.452.458.458,0,0,0-.14-.021h-.015a.452.452,0,0,0-.139.021.471.471,0,0,0-.332.452v1.608A.471.471,0,0,0,7709.212,1511.652Z" />
                                    <rect id="Rectangle_13" data-name="Rectangle 13" width="0.959" height="2.554"
                                        rx="0.475" transform="translate(7700.542 1509.119)" />
                                    <path id="Path_7" data-name="Path 7"
                                        d="M7698.63,1520.778v-7.371h13.148v3.224a3.5,3.5,0,0,1,.886.175v-4.754a1.148,1.148,0,0,0-1.145-1.146h-1.276v.294a.878.878,0,0,1-.877.877h-.015a.877.877,0,0,1-.875-.877v-.294h-6.564v.294a.88.88,0,0,1-.879.877h-.014a.876.876,0,0,1-.874-.877v-.294h-1.28a1.15,1.15,0,0,0-1.148,1.146v8.472a1.151,1.151,0,0,0,1.148,1.147h9.4a3.486,3.486,0,0,1-.268-.893Z" />
                                    <path id="Path_8" data-name="Path 8"
                                        d="M7701.585,1515.553a1.023,1.023,0,0,0-1.023-1.023h0a1.024,1.024,0,0,0-1.024,1.023h0a1.024,1.024,0,0,0,1.024,1.023h0a1.023,1.023,0,0,0,1.023-1.023Z" />
                                    <rect id="Rectangle_14" data-name="Rectangle 14" width="2.046" height="2.046"
                                        transform="translate(7702.625 1514.53)" />
                                    <rect id="Rectangle_15" data-name="Rectangle 15" width="2.046" height="2.046"
                                        transform="translate(7699.539 1517.607)" />
                                    <rect id="Rectangle_16" data-name="Rectangle 16" width="2.046" height="2.046"
                                        transform="translate(7702.625 1517.607)" />
                                    <rect id="Rectangle_17" data-name="Rectangle 17" width="2.046" height="2.046"
                                        transform="translate(7705.712 1517.607)" />
                                    <rect id="Rectangle_18" data-name="Rectangle 18" width="2.046" height="2.046"
                                        transform="translate(7705.712 1514.53)" />
                                    <rect id="Rectangle_19" data-name="Rectangle 19" width="2.046" height="2.046"
                                        transform="translate(7708.799 1514.53)" />
                                    <path id="Path_9" data-name="Path 9"
                                        d="M7711.592,1517.1a3.164,3.164,0,1,0,3.164,3.164A3.164,3.164,0,0,0,7711.592,1517.1Zm0,5.636a2.472,2.472,0,1,1,2.471-2.472A2.474,2.474,0,0,1,7711.592,1522.735Z" />
                                    <path id="Path_10" data-name="Path 10"
                                        d="M7713.12,1520.188h-1.382v-1.622a.247.247,0,1,0-.494,0v2.081c0,.137.076.233.358.233h1.518a.346.346,0,0,0,0-.692Z" />
                                </g>
                            </svg>
                            <div class="range-picker-cover">
                                <mat-date-range-input [rangePicker]="rangePicker">
                                    <input matStartDate (dateChange)="updateDateFilter('start', $event)">
                                    <input matEndDate (dateChange)="updateDateFilter('end', $event)">
                                </mat-date-range-input>
                            </div>
                            <mat-date-range-picker #rangePicker></mat-date-range-picker>
                        </span>
                    </li>
                </ul>
                <div class="filter-buttons">
                    <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
                    <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
                    <button class="snap-button primary" (click)="getFilteredTests()">Filter</button>
                </div>
            </div>
        </div>
        <button *ngIf="activeTab === TypeEnum.CASE && (totalTestCases !== 0 || checkIfFiltered(1))"
            class="snap-button primary create-new-button" routerLink="/snap/customize-test-case">
            Create a new test
        </button>
    </div>
    <div class="filters" *ngIf="activeTab === TypeEnum.RESULT && (totalTests !== 0 || checkIfFiltered())">
        <!--FILTERS FOR RESULTS-->
        <input [(ngModel)]="searchValueResults" type="text" maxlength="100" (input)="searchTests()"
            [ngModelOptions]="{standalone: true}" class="form-control search-field" placeholder="Search" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: checkIfFiltered() }" (click)="openFilters()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
            <div *ngIf="totalTests !== 0" class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <ng-multiselect-dropdown [placeholder]="'Select status'" [settings]="getDropDownSettings(1)"
                    [data]="statusList" [(ngModel)]="selectedStatuses" class="multiselect">
                </ng-multiselect-dropdown>
                <ul class="tab-container">
                    <li (click)="setDateFilter(FilterType.TODAY)"
                        [ngClass]="{'active': dateFilterResults === FilterType.TODAY }">
                        Today
                    </li>
                    <li (click)="setDateFilter(FilterType.LAST_WEEK)"
                        [ngClass]="{'active': dateFilterResults === FilterType.LAST_WEEK }">
                        Last Week
                    </li>
                    <li (click)="setDateFilter(FilterType.CUSTOM)"
                        [ngClass]="{'active': dateFilterResults === FilterType.CUSTOM }">
                        <span>
                            Custom
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14.3" viewBox="0 0 17.039 14.308"
                                fill="currentColor">
                                <g id="Group_1142" data-name="Group 1142" transform="translate(-7697.717 -1509.119)">
                                    <path id="Path_6" data-name="Path 6"
                                        d="M7709.212,1511.652a.452.452,0,0,0,.139.021h.015a.458.458,0,0,0,.14-.021.472.472,0,0,0,.333-.452v-1.608a.472.472,0,0,0-.333-.452.458.458,0,0,0-.14-.021h-.015a.452.452,0,0,0-.139.021.471.471,0,0,0-.332.452v1.608A.471.471,0,0,0,7709.212,1511.652Z" />
                                    <rect id="Rectangle_13" data-name="Rectangle 13" width="0.959" height="2.554"
                                        rx="0.475" transform="translate(7700.542 1509.119)" />
                                    <path id="Path_7" data-name="Path 7"
                                        d="M7698.63,1520.778v-7.371h13.148v3.224a3.5,3.5,0,0,1,.886.175v-4.754a1.148,1.148,0,0,0-1.145-1.146h-1.276v.294a.878.878,0,0,1-.877.877h-.015a.877.877,0,0,1-.875-.877v-.294h-6.564v.294a.88.88,0,0,1-.879.877h-.014a.876.876,0,0,1-.874-.877v-.294h-1.28a1.15,1.15,0,0,0-1.148,1.146v8.472a1.151,1.151,0,0,0,1.148,1.147h9.4a3.486,3.486,0,0,1-.268-.893Z" />
                                    <path id="Path_8" data-name="Path 8"
                                        d="M7701.585,1515.553a1.023,1.023,0,0,0-1.023-1.023h0a1.024,1.024,0,0,0-1.024,1.023h0a1.024,1.024,0,0,0,1.024,1.023h0a1.023,1.023,0,0,0,1.023-1.023Z" />
                                    <rect id="Rectangle_14" data-name="Rectangle 14" width="2.046" height="2.046"
                                        transform="translate(7702.625 1514.53)" />
                                    <rect id="Rectangle_15" data-name="Rectangle 15" width="2.046" height="2.046"
                                        transform="translate(7699.539 1517.607)" />
                                    <rect id="Rectangle_16" data-name="Rectangle 16" width="2.046" height="2.046"
                                        transform="translate(7702.625 1517.607)" />
                                    <rect id="Rectangle_17" data-name="Rectangle 17" width="2.046" height="2.046"
                                        transform="translate(7705.712 1517.607)" />
                                    <rect id="Rectangle_18" data-name="Rectangle 18" width="2.046" height="2.046"
                                        transform="translate(7705.712 1514.53)" />
                                    <rect id="Rectangle_19" data-name="Rectangle 19" width="2.046" height="2.046"
                                        transform="translate(7708.799 1514.53)" />
                                    <path id="Path_9" data-name="Path 9"
                                        d="M7711.592,1517.1a3.164,3.164,0,1,0,3.164,3.164A3.164,3.164,0,0,0,7711.592,1517.1Zm0,5.636a2.472,2.472,0,1,1,2.471-2.472A2.474,2.474,0,0,1,7711.592,1522.735Z" />
                                    <path id="Path_10" data-name="Path 10"
                                        d="M7713.12,1520.188h-1.382v-1.622a.247.247,0,1,0-.494,0v2.081c0,.137.076.233.358.233h1.518a.346.346,0,0,0,0-.692Z" />
                                </g>
                            </svg>
                            <div class="range-picker-cover">
                                <mat-date-range-input [rangePicker]="rangePicker">
                                    <input matStartDate (dateChange)="updateDateFilter('start', $event)">
                                    <input matEndDate (dateChange)="updateDateFilter('end', $event)">
                                </mat-date-range-input>
                            </div>
                            <mat-date-range-picker #rangePicker></mat-date-range-picker>
                        </span>
                    </li>
                </ul>
                <div class="filter-buttons">
                    <button class="snap-button tertiary" (click)="openFilters()">Cancel</button>
                    <button class="snap-button secondary" (click)="clearFilters()">Clear All</button>
                    <button class="snap-button primary" (click)="getFilteredTests()">Filter</button>
                </div>
            </div>
        </div>
        <button *ngIf="activeTab === TypeEnum.CASE && (totalTests !== 0 || checkIfFiltered())"
            class="snap-button primary create-new-button" routerLink="/snap/customize-test-case">
            Create a new test
        </button>
    </div>
</div>
<div class="tabs" *ngIf="isTelephonyAvailable">
    <span [ngClass]="{ active: activeTab === TypeEnum.CASE}" (click)="openTab(TypeEnum.CASE)">
        Test Cases
        <div class="total-subscriptions">
            {{totalTestCases || 0}}
        </div>
    </span>
    <span [ngClass]="{ active: activeTab === TypeEnum.RESULT}" (click)="openTab(TypeEnum.RESULT)">
        Test Results
        <div class="total-subscriptions">
            {{totalTests || 0}}
        </div>
    </span>
</div>
<div class="empty-result"
    *ngIf="isTelephonyAvailable && ((checkIfFiltered() && totalTests === 0 && !isLoading && activeTab === TypeEnum.RESULT) || (checkIfFiltered(1) && totalTestCases === 0 && !isLoading && activeTab === TypeEnum.CASE))">
    <span><img src="../../../../assets/images/no_results.svg" alt="" /></span>
    <span class="empty-search-title">No custom tests were found</span>
    <span>
        No results matched your criteria. Try adjusting your search or filter options to find what you are looking
        for.
    </span>
    <span (click)="clearFilters()" class="empty-search-action">Clear filters</span>
</div>
<div class="empty-result"
    *ngIf="isTelephonyAvailable && !isLoading
&& ((totalTests === 0 && activeTab === TypeEnum.RESULT && !checkIfFiltered()) || totalTestCases === 0 && activeTab === TypeEnum.CASE && !checkIfFiltered(1))">
    <app-empty-folder [title]="'No custom tests were found'"
        [description]="'Configure the setup for the call. Save and execute test.'"
        [actions]="activeTab === TypeEnum.CASE ? emptyActions : []"></app-empty-folder>
</div>
<div class="no-access" *ngIf="!isTelephonyAvailable">
    <p>
        Telephony testing is not available with your current subscription plan. <br />
        To get access to Telephony testing, you must upgrade your account to a plan that includes Telephony tests
        feature.
    </p>
</div>
<div class="table-container" *ngIf="activeTab === TypeEnum.CASE && isTelephonyAvailable && totalTestCases > 0">
    <table class="table test-list-table">
        <thead class="tableheading">
            <tr>
                <th scope="col" class="th_custom">Test Name</th>
                <th scope="col" class="th_custom">Feature list</th>
                <th scope="col" class="th_custom">Created at</th>
                <th scope="col" class="th_custom">Environment</th>
                <th scope="col" class="th_custom">Execution Time</th>
                <th scope="col" class="th_custom"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="position-relative" *ngFor="let testCase of testCases; index as i"
                [ngClass]="{active_prop: selectedIndex === i}">
                <td class="test-name-col">
                    <div class="text-container" [tp]="tooltipComponent" tpPlacement="top" [tpOnlyTextOverflow]="true"
                        [tpData]="testCase.name | tooltipData" [tpMaxWidth]="450">
                        <span class="type-icon-wrapper">
                            <img *ngIf="testCase.identity?.type === 'SYSTEM'" class="test-type-icon"
                                src="../../../../assets/images/snap_logo_greyscale.png" alt="">
                        </span>
                        {{testCase.name }}
                    </div>
                </td>
                <td class="custom-td">
                    <feature-list [features]="testCase.features"></feature-list>
                </td>
                <td class="custom-td">
                    <span>{{ testCase.createdAt | date: 'shortDate' }}</span>
                    <span class="device_os_grey">{{ testCase.createdAt | date: 'shortTime' }}</span>
                </td>
                <td class="custom-td">
                    <div class="env-element">
                        Devices: {{testCase.devices?.length || '-'}}
                    </div>
                </td>
                <td class="custom-td">
                    {{getExecutionTime(testCase.executionTime)}}
                </td>
                <td class="test-actions">
                    <div class="actions-wrapper">
                        <button class="test-action" mat-icon-button (click)="openNewTest(testCase)" tpPlacement="left"
                            [tp]="tooltipComponent" [tpData]="'Run test' | tooltipData">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/run.svg" alt="">
                            </span>
                        </button>
                        <button class="test-action" mat-icon-button (click)="openTestCaseDetails(i)" tpPlacement="left"
                            [tp]="tooltipComponent" [tpData]="'Show details' | tooltipData">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/eye-new.svg" alt="">
                            </span>
                        </button>
                        <button class="test-action" mat-icon-button (click)="editTest(testCase?.id)" tpPlacement="left"
                            [tp]="tooltipComponent" [tpData]="'Edit test' | tooltipData"
                            [disabled]="testCase.identity?.type === 'SYSTEM'"
                            [ngClass]="{disabled_btn: testCase.identity?.type === 'SYSTEM'}">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/NewUI/user_edit.svg" alt="">
                            </span>
                        </button>
                        <button class="test-action" mat-icon-button (click)="openConfirmDeleteTestCase(testCase?.id)"
                            tpPlacement="left" [tp]="tooltipComponent" [tpData]="'Delete test' | tooltipData"
                            [disabled]="testCase.identity?.type === 'SYSTEM'"
                            [ngClass]="{disabled_btn: testCase.identity?.type === 'SYSTEM'}">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/trash-new.svg" alt="">
                            </span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-container">
        <span class="limit">
            <label for="limit">Rows per page: </label>
            <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimitCases">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </span>
        <span>{{paginationTextCases}}</span>
        <div class="pagination-buttons">
            <div [ngClass]="{ disabled: currentPageCases === 0 }" class="arrow-button" (click)="changePage(-1)">
                <img alt="" src="../../../../assets/images/arrow_back.svg">
            </div>
            <div [ngClass]="{ disabled: currentPageCases + 1 >= totalTestCasesPage }" class="arrow-button"
                (click)="changePage(1)">
                <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
            </div>
        </div>
    </div>
</div>
<div class="table-container" *ngIf="totalTests !== 0 && activeTab === TypeEnum.RESULT && isTelephonyAvailable">
    <table class="table table_custom">
        <thead class="tableheading">
            <tr>
                <th scope="col" class="th_custom">Test Name</th>
                <th scope="col" class="th_custom">Devices</th>
                <th scope="col" class="th_custom">Executed at</th>
                <th scope="col" class="th_custom">Status</th>
                <th scope="col" class="th_custom">Iterations</th>
                <th scope="col" class="th_custom">Progress</th>
                <th scope="col" class="th_custom">Artifacts</th>
                <th scope="col" class="th_custom actions-col"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="position-relative" *ngFor="let test of customTestList">
                <td class="custom-td">
                    {{ test.name }}
                </td>
                <td class="custom-td">
                    {{ test.devices?.length }}
                    <img src="../../../../assets/images/expand_more.svg" alt="" [tp]="deviceList" class="show-devices"
                        [tpInteractive]="true" [tpData]="test.devices" [tpMaxWidth]="600" tpTrigger="manual"
                        #tooltip="tippy" (click)="toggleTooltip(tooltip)" tpPlacement="auto-end">
                    <ng-template #deviceList let-item="data">
                        <div *ngFor="let device of test.devices" class="device-details">
                            <b class="device-type">{{'Device ' + device.index + ':'}} </b>
                            {{device.modelName}}
                            <div class="inner-divider env-element">|</div>
                            {{device.osName}}
                            <div class="inner-divider env-element">|</div>
                            {{'Serial number: ' + device.serialNumber}}
                        </div>
                    </ng-template>
                </td>
                <td class="custom-td">
                    <span>{{ test.startedAt | date: 'shortDate' }}</span>
                    <span class="device_os_grey">{{ test.startedAt | date: 'shortTime' }}</span>
                </td>
                <td class="custom-td td_status">
                    <span class="_box_ remove_bg" [ngClass]="{'completed': test.status === 'COMPLETED' || test.status === 'FINALIZING',
                                                    'not_started': test.status === 'IDLE',
                                                    'stopped': test.status === 'CANCELED' || test.status === 'CANCELLING',
                                                    'failed': test.status === 'FAILED',
                                                    'in_progress': test.status === 'EXECUTING' || test.status === 'RUNNING'
                                                    }">
                    </span>
                    <span class="status">{{ displayTestStatus(test.status) }}</span>
                </td>
                <td class="custom-td">{{test.replayCount || '--'}}</td>
                <td class="custom-td">
                    <div class="results-progress">
                        <span class="success" [style.width]="getIterationWidth(test, ['completedReplayCount'])"
                            tpPlacement="top" [tp]="tooltipComponent"
                            [tpData]="(getNoOfIterationsForStatus(test, ['completedReplayCount']) + ' Pass') | tooltipData"></span>
                        <span class="failed" [style.width]="getIterationWidth(test, ['failedReplayCount'])"
                            tpPlacement="top" [tp]="tooltipComponent"
                            [tpData]="(getNoOfIterationsForStatus(test, ['failedReplayCount']) + ' Failed') | tooltipData"></span>
                        <span class="pending"
                            [style.width]="getIterationWidth(test, ['cancelledReplayCount', 'cancellingReplayCount', 'idleIterationCount', 'inProgressReplayCount'])"
                            tpPlacement="top" [tp]="tooltipComponent"
                            [tpData]="(getNoOfIterationsForStatus(test, ['cancelledReplayCount', 'cancellingReplayCount', 'idleIterationCount', 'inProgressReplayCount']) + ' Not executed') | tooltipData"></span>
                    </div>
                </td>
                <td class="custom-td artifact_status">
                    <div class="spinner_wrapper"
                        *ngIf="checkIfArtifactsFinalizing(test.logs)">
                        <mat-spinner diameter="16" color="accent"></mat-spinner>
                    </div>
                    <img class="failed-icon" src="../../../../assets/images/artifact_success.svg" alt=""
                        *ngIf="checkIfArtifactsReady(test.logs)" />
                    <img class="failed-icon" src="../../../../assets/images/artifact_failed.svg" alt=""
                        *ngIf="checkIfFailedArtifact(test.logs)" />
                    <span class="artifact-size">
                        {{getArtifactsSize(test.logs) | kilobytes: 3}}
                    </span>
                </td>
                <td class="custom-td">
                    <div class="td_menu">
                        <button mat-icon-button (click)="openTestDetails(test)" tpPlacement="left"
                            [tp]="tooltipComponent" [tpData]="'Open test report' | tooltipData">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/eye-new.svg" alt="">
                            </span>
                        </button>
                        <button mat-icon-button (click)="rerunTest(test)"
                            [disabled]="test.status === 'EXECUTING' || test.status === 'RUNNING'"
                            [ngClass]="{disabled_btn: test.status === 'EXECUTING' || test.status === 'RUNNING'}"
                            tpPlacement="left" [tp]="tooltipComponent"
                            [tpData]="'Re-run test with this configuration' | tooltipData">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/rerun_test.svg" alt="">
                            </span>
                        </button>
                        <button mat-icon-button (click)="openConfirmDelete(test.id)" tpPlacement="left"
                            [tp]="tooltipComponent" [tpData]="'Delete test result' | tooltipData">
                            <span class="menuIcon">
                                <img src="../../../../assets/images/trash-new.svg" alt="">
                            </span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="pagination-container">
        <span class="limit">
            <label for="limit">Rows per page: </label>
            <select id="limit" class="limit-select" (change)="changePageLimit()" [(ngModel)]="pageLimit">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </span>
        <span>{{paginationText}}</span>
        <div class="pagination-buttons">
            <div [ngClass]="{ disabled: currentPage === 0 }" class="arrow-button" (click)="changePage(-1)">
                <img alt="" src="../../../../assets/images/arrow_back.svg">
            </div>
            <div [ngClass]="{ disabled: currentPage + 1 >= totalPage }" class="arrow-button" (click)="changePage(1)">
                <img alt="" class="arrow-next" src="../../../../assets/images/arrow_back.svg">
            </div>
        </div>
    </div>
</div>

<mat-drawer #drawer mode="over" position="end">
    <app-telephony-test-sidepanel *ngIf="sidePanelData" (toggleSidepanelAction)="toggleSidepanelAction()"
        [testInfo]="sidePanelData"></app-telephony-test-sidepanel>
</mat-drawer>
