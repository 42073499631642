import { CommonService } from '../../../shared/common.service';
import { Router } from '@angular/router';
import { HomeServiceService } from '../../../main/home-service.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TooltipService } from '../../../shared/tooltip.service';
import { UserDataService } from '../../../shared/user-data.service';
import { User } from '../../../shared/interfaces';
import {
  AfterViewInit,
  Component,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class AppHeaderComponent implements OnDestroy, AfterViewInit {
  userData: User;
  userDataSubscription: Subscription = null;
  showImage = true;
  showHeader = false;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  closeResult: string;
  isLoading = false;
  isCropper = 'false';
  tooltipComponent = null;
  expirationTooltip = '';
  alertCount = 0;

  constructor(
    private commonService: CommonService,
    public router: Router,
    public home: HomeServiceService,
    private tooltipService: TooltipService,
    private userService: UserDataService,
  ) {
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
    this.userDataSubscription = this.userService.userData$.subscribe((user) => {
      this.userData = user;
      this.showHeader = !!user.email;
      this.getExpirationTooltip();
    });
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
    this.userDataSubscription = null;
  }

  ngAfterViewInit(): void {
    let waitForUserdata: any;
    waitForUserdata = setInterval(() => {
      if (this.showHeader) {
        clearInterval(waitForUserdata);
      }
    }, 50);
  }

  logoutUser() {
    const req = {};
    this.home.logoutApi(req).subscribe(
      (res) => {
        if (res) {
          this.userService.stopProfilePolling();
          this.commonService.logOut();
        }
      },
      (err) => { }
    );
  }
  viewProfile() {
    // if (this.userData.role !== UserRoleEnum.ADMIN) {
      this.router.navigate(['snap/profile/']);
    // }
  }

  getFullName() {
    this.isCropper = localStorage.getItem('isCropper');
    return `${this.userData.firstName} ${this.userData.lastName}`;
  }

  getUserLogo() {
    this.showImage = true;
    return this.userData.logo || '../../../../assets/images/NewUI/no_user.svg';
  }

  checkExpiring(): boolean {
    if (!this.userData.expirationDate) { return false; }
    const timeLeft = this.userData.expirationDate - (new Date().getTime() / 1000);
    const week = 604800;
    return timeLeft <= week;
  }

  getExpirationTooltip() {
    const timeLeft = this.userData.expirationDate - (new Date().getTime() / 1000);
    const days = Math.round(timeLeft / 86400);
    this.expirationTooltip = days ? `Your account expires in ${days} day${days > 1 ? 's' : ''}` : 'Your account expires today';
  }

  viewAlerts() {
    this.router.navigate(['admin/alert-list/']);
  }

  getRole(role: string): string {
    return role
      .toLowerCase()              // Convert the string to lowercase
      .split('_')                 // Split the string by underscore
      .map(word => 
        word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
      .join(' ');                 // Join the words with a space
  }
}
