import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from '../shared/network.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HomeServiceService {
  private pageChange = new BehaviorSubject('false');
  private previousUrl: string = null;
  private currentUrl: string = null;
  currentPage = this.pageChange.asObservable();

  constructor(private networkService: NetworkService,
    private router: Router
    ) { 
      this.currentUrl = this.router.url; 
      
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl ;
      this.currentUrl = event.url;
      if(this.previousUrl == null){
        let lastTab = localStorage.getItem('navigatedFrom')
        this.previousUrl = lastTab
      }
      localStorage.setItem('navigatedFrom', this.previousUrl)
    });
    }


    getNotifcation(queryPram:any) {
      return this.networkService.get("api/notifications/?"+queryPram, null, null, 'basic');
    }
    getNotifcationUnread(queryPram:any) {
      return this.networkService.get("api/notifications/count?"+queryPram, null, null, 'basic');
    }
    clearNotification() {
      return this.networkService.put("api/notifications/clear", null, null, 'basic');
    }
    createReport(body:any) {
      return this.networkService.post("api/contact/us",body, null,'basic');
    }
    logoutApi(body:any) {
      return this.networkService.post("api/auth/logout",body, null,'basic');
    }

    public getPreviousUrl(): string {
      return this.previousUrl;
    }


   
}
