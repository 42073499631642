<app-spinner *ngIf="bookingService.isLoading" [type]="'normal'"></app-spinner>
<div class="no-access" *ngIf="!isBookingAvailable">
    <p>
        Device booking is not available with your current subscription plan. <br />
        To get access to devices, you must upgrade your account to a plan that includes Booking feature.
    </p>
</div>
<section class="left-side-container" *ngIf="isBookingAvailable">
    <header>Booking manager</header>
    <div class="calendar-wrapper">
        <mat-calendar [selected]="selectedDate" (selectedChange)="onDateSelection($event)"
            [minDate]="bookingService.currentDate" [maxDate]="bookingService.maxDate" [headerComponent]="CustomCalendarHeaderComponent"></mat-calendar>
    </div>
    <div class="filters" *ngIf="deviceList.length > 0 || isFiltered">
        <input [(ngModel)]="searchText" type="text" maxlength="50" class="form-control search-field"
            placeholder="Search" (change)="filterDevices()" />
        <div class="filter-icon">
            <span class="icon" [ngClass]="{ active: isFiltered }" (click)="openFilters()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5223_41545)">
                        <path
                            d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 1.80878e-07 2.20435 1.80878e-07 3V4.17C-0.000143207 4.58294 0.0849664 4.99147 0.25 5.37V5.43C0.39128 5.75097 0.591392 6.04266 0.84 6.29L7 12.41V19C6.99966 19.1699 7.04264 19.3372 7.12487 19.4859C7.20711 19.6346 7.32589 19.7599 7.47 19.85C7.62914 19.9486 7.81277 20.0006 8 20C8.15654 19.9991 8.31068 19.9614 8.45 19.89L12.45 17.89C12.6149 17.8069 12.7536 17.6798 12.8507 17.5227C12.9478 17.3656 12.9994 17.1847 13 17V12.41L19.12 6.29C19.3686 6.04266 19.5687 5.75097 19.71 5.43V5.37C19.8888 4.99443 19.9876 4.58578 20 4.17V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM11.29 11.29C11.1973 11.3834 11.124 11.4943 11.0742 11.6161C11.0245 11.7379 10.9992 11.8684 11 12V16.38L9 17.38V12C9.00076 11.8684 8.97554 11.7379 8.92577 11.6161C8.87601 11.4943 8.80268 11.3834 8.71 11.29L3.41 6H16.59L11.29 11.29ZM18 4H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V4Z"
                            fill="#43109D" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5223_41545">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </span>
            <div class="filter-menu" [ngClass]="{ hidden: !isFilterOpen }">
                <div class="filter-wrapper">
                    <select class="form-control" [(ngModel)]="searchDeviceTypeTemp">
                        <option value="">Select Device Type</option>
                        <option value="apple">Apple</option>
                        <option value="android">Android</option>
                    </select>
                </div>
                <div class="filter-wrapper">
                    <select name="Labs" class="form-control" [(ngModel)]="searchLabTemp">
                        <option value="">All Labs</option>
                        <option [value]="item" *ngFor="let item of labListDropdown">{{item}}</option>
                    </select>
                </div>
                <div class="filter-wrapper">
                    <select name="Location" class="form-control" [(ngModel)]="searchLocationTemp">
                        <option value="">All Locations</option>
                        <option [value]="item" *ngFor="let item of locationListDropdown">{{item}}</option>
                    </select>
                </div>
                <div class="filter-buttons">
                    <button class="new-button new-secondary" (click)="cancelFilters()">Cancel</button>
                    <button class="new-button new-secondary" (click)="clearFilters()">Clear All</button>
                    <button class="new-button new-primary" (click)="applyFilters()">Filter</button>
                </div>
            </div>
        </div>
    </div>
    <div class="device-list">
        <span *ngIf="!bookingService.isLoading && !isFiltered && deviceList.length === 0" class="empty-devices">
            Devices are not available for the selected day
        </span>
        <span *ngIf="isFiltered && deviceList.length === 0" class="empty-devices">
            No results matched your criteria. Try adjusting your search or filter options to find what you are looking
            for.
        </span>
        <div *ngFor="let device of deviceList" class="device-w">
            <mat-checkbox color="primary" (change)="selectDevice($event, device)"
                [checked]="isDeviceSelected(device)"></mat-checkbox>
            <div class="device-desc">
                <span class="device-header ellipsis" [tp]="tooltipComponent" [tpData]="device.name | tooltipData"
                    [tpOnlyTextOverflow]="true">{{device?.modelName}}</span>
                <span class="device-subheader ellipsis" [tp]="tooltipComponent"
                    [tpData]="device.serialNumber | tooltipData" [tpOnlyTextOverflow]="true">{{device?.oem == 'Apple' ?
                    'UUID': 'SN'}}: {{device.serialNumber}}</span>
            </div>
        </div>
    </div>
</section>
<section class="right-side-container" *ngIf="isBookingAvailable">
    <app-booking-scheduler (selectSlot)="onSelectTimeSlot($event)"></app-booking-scheduler>
</section>

<ng-template #bookingModal let-modal>
    <app-booking-modal (close)="closeModal()" (devicesBooked)="devicesBooked()"></app-booking-modal>
</ng-template>