import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AlreadyBookedResponse, BestSlot, BookingType, CreateBookingForm, DayOfWeek, UpdateBookingForm } from "../../interfaces";
import { MatSelectChange } from "@angular/material/select";
import { BookingManagerService } from "../../booking-manager.service";
import { Subscription } from "rxjs";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TooltipService } from "../../../../shared/tooltip.service";

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit, OnDestroy {
  @Output() devicesBooked = new EventEmitter();
  @Output() close = new EventEmitter();

  selectedForBooking = [];
  bookingFrom: Date;
  bookingTimeFrom = '';
  bookingTo: Date;
  bookingTimeTo = '';
  endDate: Date;
  type = 'SINGLE';

  times = Array.from(Array(48)).map((_x, i) => ({ hour: Math.floor(i / 2).toString().padStart(2, '0'), minute: i % 2 === 0 ? '00' : '30' }));
  timesTo = [];
  startDate = new Date();
  minDate: Date;
  maxDate: Date;
  maxEndDate: Date;
  description = '';
  week = [DayOfWeek.SUNDAY, DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY, DayOfWeek.SATURDAY];
  selectedDevices = [];
  selectedDevicesSubscription: Subscription;
  selectedDate = new Date();
  selectedDateSubscription: Subscription;
  errorMessage = '';
  errors = [];
  duration = '';
  modalRef: NgbModalRef;
  modelOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  tooltipComponent = null;

  bestSlot: BestSlot;

  @ViewChild('bestSlotModal', { static: false }) bestSlotModal: TemplateRef<void>;

  constructor(
    public bookingService: BookingManagerService,
    private modalService: NgbModal,
    private tooltipService: TooltipService,
  ) {
    this.selectedDevicesSubscription = this.bookingService.selectedDevices$.subscribe((devices) => {
      // TODO edit recurrent
      if(this.bookingService.selectedTimeSlot.booking) {
        this.selectedDevices = [devices[this.bookingService.selectedTimeSlot.device]];
      } else {
        this.selectedDevices = devices;
      }
    });
    this.selectedDateSubscription = this.bookingService.selectedDate$.subscribe((date) => this.selectedDate = date);
    this.tooltipComponent = this.tooltipService.getTooltipComponent();
  }

  ngOnInit(): void {
    if (this.bookingService.selectedTimeSlot) {
      const minute = this.bookingService.selectedTimeSlot.half === 1 ? 0 : 30;
      if (this.bookingService.selectedTimeSlot.booking) {
        this.selectedForBooking = this.selectedDevices.filter((d) => d._id === this.bookingService.selectedTimeSlot.booking.deviceId);
        const dateFrom = new Date(this.bookingService.selectedTimeSlot.booking.bookedFrom * 1000);
        this.bookingTimeFrom = dateFrom.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        dateFrom.setHours(0, 0, 0, 0);
        const dateUntil = new Date((this.bookingService.selectedTimeSlot.booking.bookedUntil + 1) * 1000);
        this.bookingTimeTo = dateUntil.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        dateUntil.setHours(0, 0, 0, 0);
        this.bookingFrom = dateFrom;
        this.bookingTo = dateUntil;
        this.duration = this.calculateDuration();
        this.description = this.bookingService.selectedTimeSlot.booking.description;
      } else {
        this.selectedForBooking = [this.selectedDevices[this.bookingService.selectedTimeSlot.device]];
        this.bookingFrom = this.selectedDate;
        this.bookingFrom.setHours(this.bookingService.selectedTimeSlot.hour, minute, 0, 0);

        const now = new Date()
        if (this.bookingFrom < now) {
          this.bookingTimeFrom = now.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
          this.bookingTimeTo = new Date(now.getTime() + 1800000).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        } else {
          this.bookingTimeFrom = this.bookingFrom.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
          this.bookingTimeTo = new Date(this.bookingFrom.getTime() + 1800000).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        }
        const timeSplit = this.parseTime(this.bookingTimeFrom);
        this.bookingFrom.setHours(0, 0, 0, 0);
        this.bookingTo = this.selectedDate;
        this.adjustTimesTo(timeSplit.hours.toString().padStart(2, '0'), timeSplit.minutes.toString().padStart(2, '0'));
        this.duration = this.calculateDuration();
      }
    } else {
      this.timesTo = [...this.times];
    }
    this.startDate.setHours(0, 0, 0, 0);

    this.minDate = this.bookingFrom || this.startDate;
    this.maxDate = this.bookingService.maxDate;
    this.maxEndDate = this.bookingService.maxDate;
  }

  ngOnDestroy(): void {
    this.selectedDevicesSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
  }

  selectForBooking($event: MatCheckboxChange, device: any) {
    if ($event.checked) {
      this.selectedForBooking.push(device);
    } else {
      this.selectedForBooking = this.selectedForBooking.filter((d) => d.serialNumber !== device.serialNumber);
    }
  }

  checkCreateReady(): boolean {
    const dailyVerification = this.type === 'DAILY' && this.bookingTo?.getTime() === this.bookingFrom?.getTime();
    const weeklyVerification = this.type === 'WEEKLY' && this.bookingTo?.getTime() - this.bookingFrom?.getTime() <= 518400000;

    return !(this.selectedForBooking.length
      && this.bookingFrom
      && this.bookingTo
      && this.bookingTimeFrom
      && this.bookingTimeTo
      && this.bookingTo >= this.bookingFrom
      && this.bookingTo <= this.maxDate
      && (this.type === 'SINGLE' || (this.endDate && (dailyVerification || weeklyVerification)))
    );
  }

  calculateDuration(): string {
    if (this.bookingFrom && this.bookingTimeFrom && this.bookingTo && this.bookingTimeTo) {
      const from = new Date(this.bookingFrom.toLocaleDateString('en-US') + ' ' + this.bookingTimeFrom);
      const to = new Date(this.bookingTo.toLocaleDateString('en-US') + ' ' + this.bookingTimeTo);
      let difference = (to.getTime() - from.getTime()) / 1000;

      const days = Math.floor(difference / 86400);
      difference = difference - days * 86400;
      const hours = Math.floor(difference / 3600);
      difference = difference - hours * 3600;
      const minutes = Math.floor(difference / 60);
      return `${days > 0 ? days + ' days ' : ''}${hours > 0 ? hours + ' hours ' : ''}${minutes} minutes`;
    }
    return '';
  }

  onDurationChange($event) {
    let days = 0;
    let hours = 0;
    let minutes = 0;
    const durationSplit = $event.target.value.split(' ');

    const dayIndex = durationSplit.findIndex((value) => value === 'days' || value === 'day' || value === 'd');
    if (dayIndex > 0) {
      days = parseInt(durationSplit[dayIndex - 1]);
    }

    const hourIndex = durationSplit.findIndex((value) => value === 'hours' || value === 'hour' || value === 'h');
    if (hourIndex > 0) {
      hours = parseInt(durationSplit[hourIndex - 1]);
    }

    const minuteIndex = durationSplit.findIndex((value) => value === 'minutes' || value === 'minute' || value === 'm');
    if (minuteIndex > 0) {
      minutes = parseInt(durationSplit[minuteIndex - 1]);
    }

    if (dayIndex < 0 && hourIndex < 0 && minuteIndex < 0 || isNaN(days) || days < 0 || isNaN(hours) || hours < 0 || isNaN(minutes) || minutes < 0) {
      $event.target.value = this.duration;
    } else {
      this.duration = $event.target.value;
      const dateFrom = new Date(this.bookingFrom.toLocaleDateString('en-US') + ' ' + this.bookingTimeFrom);
      const minutesToMs = minutes ? minutes * 60000 : 0;
      const hoursToMs = hours ? hours * 3600000 : 0;
      const daysToMs = days ? days * 86400000 : 0;
      const dateTo = new Date(dateFrom.getTime() + daysToMs + hoursToMs + minutesToMs);
      this.bookingTimeTo = dateTo.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      dateTo.setHours(0, 0, 0, 0);
      this.bookingTo = dateTo;
    }
  }

  submitForm() {
    this.bookingService.selectedTimeSlot.booking ? this.updateBooking() : this.createBooking();
  }

  createBooking() {
    this.bookingService.startLoading();
    this.errorMessage = '';
    this.errors = [];

    const endDate = new Date(new Date(this.bookingTo.toLocaleDateString('en-US') + ' ' + this.bookingTimeTo).getTime() - 60000);

    const form: CreateBookingForm = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceIdList: this.selectedForBooking.map((device) => device._id),
      type: this.type === 'SINGLE' ? BookingType.SINGLE : BookingType.RECURRENT,
      startDate: this.formatDate(this.bookingFrom),
      endDate: this.formatDate(this.bookingTo),
      startTime: this.formatTime(this.bookingTimeFrom),
      endTime: this.formatTime(endDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })),
      description: this.description
    };

    if (this.type === 'DAILY') {
      form.endDate = this.formatDate(this.endDate);
      form.days = this.week;
    }

    if (this.type === 'WEEKLY') {
      const startDay = this.bookingFrom.getDay();
      const endDay = this.bookingTo.getDay();
      form.endDate = this.formatDate(this.endDate);
      if (this.bookingFrom.getDate() === this.bookingTo.getDate()) {
        form.days = [this.week[startDay]];
      } else {
        form.days = startDay === endDay ? this.week : startDay < endDay ? this.week.slice(startDay, endDay + 1) : [...this.week.slice(startDay), ...this.week.slice(0, endDay + 1)];
      }
    }

    this.bookingService.createBooking(form).subscribe((res) => {
      this.bookingService.stopLoading();

      if (res.statusCode === 200) {
        this.devicesBooked.emit();
      }
    }, (error) => {
      this.bookingService.stopLoading();
      if (error.error.data.bookings) {
        const response = error.error.data as AlreadyBookedResponse;
        this.errorMessage = response.message;
        this.errors = response.bookings.map((booking) => {
          const device = this.selectedDevices.find((d) => d._id === booking.deviceId);
          return {
            ...booking,
            deviceName: device.modelName,
            bookedFrom: booking.bookedFrom * 1000,
            bookedUntil: (booking.bookedUntil + 1) * 1000
          };
        });
      }
    });
  }

  updateBooking() {
    this.bookingService.startLoading();
    this.errorMessage = '';
    this.errors = [];

    const endDate = new Date(new Date(this.bookingTo.toLocaleDateString('en-US') + ' ' + this.bookingTimeTo).getTime() - 60000);

    const form: UpdateBookingForm = {
      bookingId: this.bookingService.selectedTimeSlot.booking.id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate: this.formatDate(this.bookingFrom),
      endDate: this.formatDate(this.bookingTo),
      startTime: this.formatTime(this.bookingTimeFrom),
      endTime: this.formatTime(endDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })),
      description: this.description
    };

    // TODO edit recurrent
    // if (this.type === 'DAILY') {
    //   form.endDate = this.formatDate(this.endDate);
    //   form.days = this.week;
    // }

    // if (this.type === 'WEEKLY') {
    //   const startDay = this.bookingFrom.getDay();
    //   const endDay = this.bookingTo.getDay();
    //   form.endDate = this.formatDate(this.endDate);
    //   form.days = startDay === endDay ? this.week : startDay < endDay ? this.week.slice(startDay, endDay + 1) : [...this.week.slice(startDay), ...this.week.slice(0, endDay + 1)];
    // }

    this.bookingService.updateBooking(form).subscribe((res) => {
      this.bookingService.stopLoading();

      if (res.statusCode === 200) {
        this.devicesBooked.emit();
      }
    }, (error) => {
      this.bookingService.stopLoading();
      if (error.error.data.bookings) {
        const response = error.error.data as AlreadyBookedResponse;
        this.errorMessage = response.message;
        this.errors = response.bookings.map((booking) => {
          const device = this.selectedDevices.find((d) => d._id === booking.deviceId);
          return {
            ...booking,
            deviceName: device.modelName,
            bookedFrom: booking.bookedFrom * 1000,
            bookedUntil: (booking.bookedUntil + 1) * 1000
          };
        });
      }
    });
  }

  formatDate(date: Date): string {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  }

  formatTime(timeString: string): string {
    const timeParsed = this.parseTime(timeString);
    return `${timeParsed.hours.toString().padStart(2, '0')}:${timeParsed.minutes.toString().padStart(2, '0')}`;
  }

  closeModal() {
    this.close.emit();
  }

  onDateChange($event: MatDatepickerInputEvent<Date>, prop: string) {
    this[prop] = $event.value;
    if (this.bookingTimeFrom) {
      const timeSplit = this.parseTime(this.bookingTimeFrom);
      const hour = timeSplit.hours.toString().padStart(2, '0');
      const minute = timeSplit.minutes.toString().padStart(2, '0');
      this.adjustTimesTo(hour, minute);
    }

    if (prop === 'bookingFrom') {
      this.minDate = this.bookingFrom;
      this.setMaxDate(this.type);
    }
    this.duration = this.calculateDuration();
  }

  checkSameDay() {
    return this.bookingFrom && this.bookingTo && this.bookingFrom.getDate() === this.bookingTo.getDate() && this.bookingFrom.getMonth() === this.bookingTo.getMonth();
  }

  adjustTimesTo(hour: string, minute: string) {
    if (this.checkSameDay()) {
      const timeIndex = this.times.findIndex((t) => {
        const setMinute = parseInt(minute) > 0 ? '30' : '00';
        return t.hour === hour && t.minute === setMinute;
      });
      this.timesTo = this.times.slice(timeIndex);
      const timeFrom = this.parseTime(this.bookingTimeFrom);
      const timeTo = this.parseTime(this.bookingTimeTo);
      if (this.checkSameDay() && (timeFrom.hours > timeTo.hours || (timeFrom.hours === timeTo.hours && timeFrom.minutes > timeTo.minutes))) {
        this.bookingTimeTo = this.bookingTimeFrom;
      }
    } else {
      this.timesTo = [...this.times];
    }
  }

  onTimeSelect($event: MatSelectChange, prop: 'bookingTimeFrom' | 'bookingTimeTo') {
    let hour = $event.value.hour;
    let minute = $event.value.minute;
    const now = new Date();
    if (this.checkTodayValidity(now, parseInt(hour), parseInt($event.value.minute))) {
      this[prop] = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      const timeSplit = this.parseTime(this[prop]);
      hour = timeSplit.hours.toString().padStart(2, '0');
      minute = timeSplit.minutes.toString().padStart(2, '0');
    } else {
      this[prop] = `${hour === '00' ? '12' : hour > 12 ? (hour - 12).toString().padStart(2, '0') : hour}:${minute} ${hour > 11 ? 'PM' : 'AM'}`;
    }
    if (prop === 'bookingTimeFrom') {
      this.adjustTimesTo(hour, minute);
    }
    this.duration = this.calculateDuration();
  }

  onDescriptionChange($event) {
    this.description = $event.target.value;
  }

  setMaxDate(type: string) {
    const from = this.bookingFrom.getTime();
    switch (type) {
      case 'SINGLE':
        this.maxDate = this.bookingService.maxDate;
        break;
      case 'DAILY':
        this.maxDate = this.bookingFrom;
        break;
      case 'WEEKLY':
        this.maxDate = from + 518400000 > this.bookingService.maxDate.getTime() ? this.bookingService.maxDate : new Date(from + 518400000);
        break;
    }
  }

  onTypeChange($event: MatSelectChange) {
    this.setMaxDate($event.value);
  }

  isDeviceSelected(device: any): boolean {
    return this.selectedForBooking.some((d) => d._id === device._id);
  }

  isEdit(): boolean {
    return !!this.bookingService.selectedTimeSlot.booking;
  }

  findBestSlot() {
    this.bestSlot = null;
    const from = new Date(this.bookingFrom.toLocaleDateString('en-US') + ' ' + this.bookingTimeFrom);
    const to = new Date(new Date(this.bookingTo.toLocaleDateString('en-US') + ' ' + this.bookingTimeTo).getTime() - 60000);
    const duration = (to.getTime() - from.getTime()) / 60000;

    const request = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceIds: this.selectedForBooking.map((d) => d._id),
      startDate: this.formatDate(this.bookingFrom),
      startTime: this.formatTime(this.bookingTimeFrom),
      duration
    };

    this.bookingService.startLoading();
    this.bookingService.findBestSlot(request).subscribe((res) => {
      this.bookingService.stopLoading();
      if (res.statusCode === 200) {
        if (!res.data['message']) {
          this.bestSlot = res.data as BestSlot;
        }
        this.modalRef = this.modalService.open(this.bestSlotModal, this.modelOptions);
      }
    }, (error) => {
      this.bookingService.stopLoading();
      this.modalRef = this.modalService.open(this.bestSlotModal, this.modelOptions);
    });
  }

  closeSlotModal() {
    this.modalRef.close();
  }

  applyBestSlot() {
    const dateFrom = new Date(this.bestSlot.startDateTime * 1000);
    this.bookingTimeFrom = dateFrom.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    dateFrom.setHours(0, 0, 0, 0);
    const dateUntil = new Date((this.bestSlot.endDateTime + 1) * 1000);
    this.bookingTimeTo = dateUntil.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    dateUntil.setHours(0, 0, 0, 0);
    this.bookingFrom = dateFrom;
    this.bookingTo = dateUntil;
    this.duration = this.calculateDuration();
    this.modalRef.close();
  }
  
  checkTodayValidity(date: Date, hour: number, minute: number): boolean {
    return this.bookingFrom.getDate() === date.getDate()
      && this.bookingFrom.getMonth() === date.getMonth()
      && this.bookingFrom.getFullYear() === date.getFullYear()
      && (hour < date.getHours()
        || (hour === date.getHours() && minute < date.getMinutes()));
  }

  onTimeChange($event, prop: string) {
    const time = $event.target.value;
    if (time.match(/^(0?[1-9]|1[012]):[0-5][0-9] ([AaPp][Mm])$/)) {
      const timeSplit = this.parseTime(time);
      if (prop === 'bookingTimeTo') {
        const timeFrom = this.parseTime(this.bookingTimeFrom);
        if (this.checkSameDay() && (timeSplit.hours < timeFrom.hours || (timeSplit.hours === timeFrom.hours && timeSplit.minutes < timeFrom.minutes))) {
          this[prop] = this.bookingTimeFrom;
          $event.target.value = this[prop];
        } else {
          this[prop] = time;
        }
      } else {
        if (this.checkTodayValidity(new Date(), timeSplit.hours, timeSplit.minutes)) {
          this[prop] = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
          $event.target.value = this[prop];
        } else {
          this[prop] = time;
          this.adjustTimesTo(timeSplit.hours.toString().padStart(2, '0'), timeSplit.minutes.toString().padStart(2, '0'));
        }
      }
    } else {
      this[prop] = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      $event.target.value = this[prop];
    }
    this.duration = this.calculateDuration();
  }
  parseTime(timeString: string): { hours: number, minutes: number } {
    const timeSplit = timeString.split(':');
    const minuteSplit = timeSplit[1].split(' ');
    if (minuteSplit[1] === 'AM' && timeSplit[0] === '12') {
      return { hours: 0, minutes: parseInt(minuteSplit[0]) };
    }
    if (minuteSplit[1] === 'PM') {
      return { hours: parseInt(timeSplit[0]) + 12, minutes: parseInt(minuteSplit[0]) };
    }
    return { hours: parseInt(timeSplit[0]), minutes: parseInt(minuteSplit[0]) };
  }
}