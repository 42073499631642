import { Component, OnInit } from '@angular/core';
import { TokenData } from '../../shared/interfaces';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-relase-notes',
  templateUrl: './relase-notes.component.html',
  styleUrls: ['./relase-notes.component.scss']
})
export class RelaseNotesComponent implements OnInit {
  userData: TokenData;
  releases = [
    {
      id: 'v1.11.1',
      name: 'v1.11.1',
      date: 'September 9, 2024',
      notes: [
        'Emulators support.'
      ]
    },
    {
      id: 'v1.11.0',
      name: 'v1.11.0',
      date: 'August 16, 2024',
      notes: [
        'New booking manager.',
        'EAPI extension. New booking manager. Attach/Detach. ADB access.',
        'Power Button support.',
        'Additional telephony tests. Network performance test.',
        'Test artefacts. Device and Action logs, KPIs collecting.',
        'Appium. Installing apps from Snap Cloud.',
        'Appium. Obtaining Logcat logs on Snap.',
        'OTP support for new users.',
        'ChatGPT support for SNAP platform documentation.',
        'Highest priority bug fixes.'
      ]
    },
    {
      id: 'v1.10.8',
      name: 'v1.10.8',
      date: 'July 7, 2024',
      notes: [
        'Device name changing fixed',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.7',
      name: 'v1.10.7',
      date: 'July 2, 2024',
      notes: [
        'SNAP Cloud folders sharing support on Enterprise level',
        'ADB access over external API fixed',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.6',
      name: 'v1.10.6',
      date: 'June 14, 2024',
      notes: [
        'External API upgrade',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.5',
      name: 'v1.10.5',
      date: 'May 31, 2024',
      notes: [
        'One time password reset for the new users',
        'Device boot duration check configuration',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.4',
      name: 'v1.10.4',
      date: 'May 7, 2024',
      notes: [
        'Network performance test',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.3',
      name: 'v1.10.3',
      date: 'April 25, 2024',
      notes: [
        'ChatGPT floating point button support',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.2',
      name: 'v1.10.2',
      date: 'April 15, 2024',
      notes: [
        'ChatGPT support for SNAP platform documentation',
        'Added capability to get Logcat by Appium from Snap Platform',
        'Highest priority bug fixes'
      ]
    },
    {
      id: 'v1.10.1',
      name: 'v1.10.1',
      date: 'April 8, 2024',
      notes: [
        'New 7 telephony tests',
        'Added capability to install apps by Appium from Snap Cloud',
        'Device and Action logs collection managed from Snap web portal',
        'Support new device KPIs collection',
        'Additional WiFi (Rx/Tx) info collecting'
      ]
    },
    {
      id: 'v1.10.0',
      name: 'v1.10.0',
      date: 'January 31, 2024',
      notes: [
        'External API: Authorization refactoring',
        'External API: 80 new APIs added',
        'Subscription model introduction',
        'Platform UX refactoring (Test, Subscription)',
        'New User roles for Enterprise',
        'Smart-hub support'
      ]
    },
    {
      id: 'v1.9.0',
      name: 'v1.9.0',
      date: 'October 6, 2023',
      notes: [
        'MO-MT calls support',
        'Manual tests support',
        'Active device screen redesign',
        'Snap Cloud. Folders structure improvements',
        'Device properties support',
      ]
    },
    {
      id: 'v1.8.1',
      name: 'v1.8.1',
      date: 'August 21, 2023',
      notes: [
        'Device Control refactoring',
        'System performance improvements',
        'Booking logic bugfix',
        'Screencast freeze fix',
        'UI performance bugfix',
      ]
    },
    {
      id: 'v1.8.0',
      name: 'v1.8.0',
      date: 'August 4, 2023',
      notes: [
        'External API & Webhooks',
        'Record and Play. No code testing',
        'iOS Debug tools',
        'New iOS gestures support',
        'Device audio out support',
        'Device booking and info enhancements',
        'Device states journaling',
        'Typing speed and Images rendering improvements',
      ]
    },
    {
      id: 'v1.7.0',
      name: 'v1.7.0',
      date: 'May 12, 2023',
      notes: [
        'ADB full-fledged tool. Save to file & clear ops. Push/pull support. Adjustable size',
        'ADB logcat. Simplified and manual ones with grep, search and filtering support',
        'ADB fully functional terminal',
        'Remote device attach/detach',
        'Appium Inspector',
        'Plug & Play',
        'Extended device states',
        'Device Reset',
        'Device daily monitoring routine',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.6.1',
      name: 'v1.6.1',
      date: 'April 10, 2023',
      notes: [
        'ADB push & pull file',
        'UI enhancements for ADB',
        'Improved ADB logcat buffers selection',
        'ADB logcat filter and grep functionality',
        'Capability to observe a person username who occupied the device',
        'Enhancements of device state capturing',
        'Support for latest version of iOS/Android',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.6.0',
      name: 'v1.6.0',
      date: 'January 27, 2023',
      notes: [
        'Appium integration: Supports automation testing',
        'Improved performance of iPhone screencast',
        'Phone logs and videos collecting implemented for Appium scripts and manual testing',
        'Support for latest version of iOS/Android',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.5.0',
      name: 'v1.5.0',
      date: 'October 24, 2022',
      notes: [
        'External API added to support integration with customer’s existing interface',
        'Minicap support for higher frame rates for smooth screencast',
        'Cleaner UI for better user experience',
        'Real time alerts to enterprise admins for any device disconnect/lab status',
        'Support for latest version of iOS/Android',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.4.0',
      name: 'v1.4.0',
      date: 'September 21, 2022',
      notes: [
        'UI updated to Angular Framework to 13.1 version',
        'Ability to see carrier name,phone number and toggle between connectivity (5G, 4G)',
        'Screencapture support and user interaction logs',
        'Enable touch\\gesture visualisation on device screen',
        'Support sending ADB options',
        'Improved android and SNAP client monitoring',
        'Automation restoration of disconnected devices',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.3.0',
      name: 'v1.3.0',
      date: 'July 22, 2022',
      notes: [
        'Added new feature where user can change device Orientation and set mock geolocation',
        'User can access android device using adb shell',
        'Improved iOS monitoring',
        'Improved process to support faster deployments in new labs',
        'Performance improvements and bug fixes',
      ]
    },
    {
      id: 'v1.2.0',
      name: 'v1.2.0',
      date: 'April 2, 2022',
      notes: [
        'Revamped UI for user/enterprise',
        'Multiple Device booking',
        'Enterprise and role based users',
        'Cloud based centralised frontend/backend',
        'Backend optimisations and bug fixes',
        'Performance improvements and bug fixes',
      ]
    },
  ];

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.userData = this.commonService.getUser();
  }

  toVersion(id: any) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }
}
